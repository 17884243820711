import { Tooltip as ChakraToolTip, TooltipProps } from '@chakra-ui/react'
import { useTheme } from 'next-themes'
import { FC } from 'react'

const ToolTip: FC<TooltipProps> = ({ children, ...rest }) => {
	const { theme } = useTheme()

	return (
		<ChakraToolTip
			placement="top"
			bg={theme === 'light' ? '#414141' : '#605E8A'}
			color={theme === 'light' ? '#FFFFFF' : '#0A0910'}
			borderRadius="sm"
			{...rest}
		>
			{children}
		</ChakraToolTip>
	)
}

// TODO: Implement with forward Ref and switch colors using CSS
// const Content = forwardRef<HTMLDivElement, TooltipProps>(({ children, ...rest }, ref) => {
// 	return (
// 		<Box className="text-color-0 bg-color-6 text-xs font-medium rounded-sm py-0.5 px-1" ref={ref} {...rest}>
// 			{children}
// 		</Box>
// 	)
// })

// Content.displayName = 'Content'

export default ToolTip
