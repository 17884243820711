import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import backend from '../../apiUtils/backend'
import { isProduction } from '../../helpers/constants'
import { FeedResult } from '../../interfaces/feed.interface'
import { getMilliFromMinutes } from '../../utils/rq-settings'

const getFeed = async ({
	queryKey,
	pageParam: skip,
}: QueryFunctionContext<[string, string | undefined, number | undefined], number | undefined>): Promise<FeedResult> => {
	const [, address, limit] = queryKey

	const queryParams = new URLSearchParams()
	if (skip) queryParams.append('skip', skip.toString())
	if (limit) queryParams.append('limit', limit.toString())
	if (address) queryParams.append('user', address)
	if (isProduction) queryParams.append('production', 'true')

	return backend.get<FeedResult>(`/feed?${queryParams.toString()}`).then((res) => res.data)
}

const useInfiniteFeed = (address?: string, limit?: number): UseInfiniteQueryResult<FeedResult> => {
	const query = useInfiniteQuery(['feed', address, limit], getFeed, {
		getNextPageParam: (lastPage) => lastPage.nextSkip || undefined,
		staleTime: getMilliFromMinutes(1),
		cacheTime: getMilliFromMinutes(10),
	})

	useBottomScrollListener(() => {
		if (query.hasNextPage && !query.isFetchingNextPage) {
			query.fetchNextPage()
		}
	})

	return query
}

export default useInfiniteFeed
