import { useQuery, UseQueryResult } from 'react-query'
import { getVestByAddress } from '../../helpers/queryFunctions'
import { Vest } from '../../interfaces/interfaces'

export type VestAddressType = 'creator' | 'beneficiary'

const useVestsByAddress = (type: VestAddressType, address: string): UseQueryResult<Vest[]> => {
	return useQuery<Vest[]>(['vest-by-address', type, address], getVestByAddress)
}
export default useVestsByAddress
