import { useAppContext } from '@components/app-context'
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds'
import { getUserMemberships } from 'helpers/queryFunctions'
import { InfiniteMembershipResponse } from 'interfaces/interfaces'
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'

/**
 *
 * @param address of User
 * @returns Returns Array of Memberships that user joined/purchased
 */
const useUserMemberships = (address: string): UseInfiniteQueryResult<InfiniteMembershipResponse> => {
	const { chainId } = useAppContext()

	return useInfiniteQuery(['user-memberships', address], getUserMemberships, {
		getNextPageParam: (lastPage) => lastPage.skip,
		retry: false,
		staleTime: minutesToMilliseconds(5),
		refetchInterval: minutesToMilliseconds(5),
		refetchOnWindowFocus: true,
		enabled: !!(chainId && address),
	})
}

export default useUserMemberships
