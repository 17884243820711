import axios, { AxiosError, AxiosResponse } from 'axios'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { CurrentProfile, FollowProfile, User } from '../interfaces/interfaces'

const useUnfollow = (): UseMutationResult<
	AxiosResponse<any>,
	AxiosError<any>,
	User,
	{
		previousProfile: CurrentProfile
	}
> => {
	const queryClient = useQueryClient()
	return useMutation<AxiosResponse<any>, AxiosError, User, { previousProfile: CurrentProfile }>(
		(user) => axios.post('/api/user/unfollow', { address: user.wallet_address }, { withCredentials: true }),
		{
			onMutate: async (user) => {
				await queryClient.cancelQueries('current_profile')
				const previousProfile = queryClient.getQueryData<CurrentProfile>('current_profile')

				queryClient.setQueryData<{
					user: User
					followings: FollowProfile[]
				}>('current_profile', (prev) => ({
					...prev,
					followings: prev.followings.filter((u) => u.wallet_address !== user.wallet_address),
				}))

				return { previousProfile }
			},
			onError: (err, user, context) => {
				queryClient.setQueryData('current_profile', context.previousProfile)
			},
			onSettled: () => {
				queryClient.invalidateQueries('current_profile')
			},
		}
	)
}

export default useUnfollow
