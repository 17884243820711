import { Skeleton } from '@chakra-ui/react'
import {
	CommunityView,
	CoverImage,
	FeedView,
	MembershipsView,
	ParticipateView,
	ProfileCard,
} from '@components/byPage/profile'
import NotFound404 from '@components/common/NotFound404'
import { Avatar } from '@components/core'
import { Button } from '@components/core/Button/Button'
import { HexNftIcon, WidgetsIcon } from '@components/icons'
import Layout from '@components/Layout/Layout'
import { Tab, TabProps } from '@components/NewDashboard/Tabs'
import StyledLoading from '@components/StyledLoading'
import useFeaturedUsers from '@hooks/query/useFeaturedUsers'
import useProfile from '@hooks/query/useProfile'
import useCurrentProfile from '@hooks/useCurrentProfile'
import useQueryParam from '@hooks/useQueryParam'
import { getProfile } from 'helpers/queryFunctions'
import { GetServerSideProps } from 'next'
import { CreatorsCard } from 'pages/creators'
import { FC, useMemo } from 'react'
import { QueryClient } from 'react-query'
import { dehydrate } from 'react-query/hydration'

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
	const slug_address = query.profile

	if (!slug_address) {
		return { props: {} }
	}

	// TODO: Check what this is?
	if (slug_address.includes('favicon.ico')) {
		return { props: {} }
	}

	const queryClient = new QueryClient()

	await queryClient.prefetchQuery(['profile', slug_address], getProfile)

	return {
		props: { dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))) },
	}
}

enum ProfileView {
	Community = 'community',
	Participate = 'participate',
	Memberships = 'memberships',
}
const profileViews = Object.values(ProfileView)

const Profile: FC = () => {
	const { data: profile, isLoading: isProfileLoading } = useProfile()
	const { data: loggedInUser } = useCurrentProfile()

	const isSelf = useMemo(
		() =>
			profile && loggedInUser
				? loggedInUser.user.wallet_address.toLowerCase() === profile.user.wallet_address.toLowerCase()
				: false,
		[loggedInUser, profile]
	)

	const isFollowing = useMemo(
		() =>
			profile && loggedInUser
				? loggedInUser.followings.some(
						(followee) => followee.wallet_address.toLowerCase() === profile.user.wallet_address.toLowerCase()
				  )
				: false,
		[loggedInUser, profile]
	)

	// TODO: use when memberships are added
	// const userHasMembership = false
	// const defaultView = userHasMembership ? ProfileView.Participate : ProfileView.Community
	const defaultView = ProfileView.Community

	const [view, setView] = useQueryParam<ProfileView>('view', {
		defaultValue: defaultView,
		allowedValues: profileViews,
	})

	const { data: featuredUsersData, isLoading } = useFeaturedUsers()

	const profileTabs: Array<Omit<TabProps<ProfileView>, 'currentTab' | 'onSelect'>> = [
		{
			id: ProfileView.Memberships,
			imageComponent: (
				<HexNftIcon className="shrink-0 w-7 h-7 fill-color-6" gradient={view === ProfileView.Memberships} />
			),
		},
		{
			id: ProfileView.Community,
			imageComponent: (
				<Avatar
					size="sm"
					profilePicUrl={profile?.user.profile_pic_url}
					gradient1={profile?.user.gradient_1}
					gradient2={profile?.user.gradient_2}
				/>
			),
		},
		{
			id: ProfileView.Participate,
			imageComponent: (
				<WidgetsIcon className="shrink-0 w-7 h-7 fill-color-6" gradient={view === ProfileView.Participate} />
			),
		},
	]

	return (
		<>
			<Layout
				metaImage={profile?.user.profile_pic_url || null}
				title={profile?.user.user_name ?? ''}
				description="View this profile on Coinvise and participate in active airdrops, quests and exclusive updates"
			>
				{isProfileLoading ? (
					<StyledLoading />
				) : profile ? (
					<>
						<div className="relative w-full min-h-screen">
							<CoverImage profile={profile} />
							<div className="px-5">
								<div className="px-5">
									<ProfileCard profile={profile} isSelf={isSelf} isFollowing={isFollowing} />
								</div>
								<div className="flex items-center justify-center lg:justify-between w-full lg:w-min px-4 py-3 rounded-full lg:bg-color-0 gap-4 mb-10 lg:mb-20 mx-auto -translate-y-[60px] lg:translate-y-0">
									{profileTabs.map((tab) => (
										<Tab
											key={tab.id}
											id={tab.id}
											imageComponent={tab.imageComponent}
											currentTab={view}
											onSelect={setView}
										/>
									))}
								</div>

								<RenderTabContent view={view} />
							</div>
							{isLoading ? (
								<Skeleton />
							) : (
								<div
									className={'hidden lg:flex w-full px-10 flex-col bg-color-2 justify-center items-center gap-20 py-20'}
								>
									<span className="text-color-6 font-semibold text-[26px]">Featured Communities</span>
									<div className="flex flex-row gap-4 md:gap-14 flex-wrap justify-center w-full xl:w-[1080px]">
										{featuredUsersData.pages.map((grp) =>
											grp.users.map(
												(user, i) =>
													user.wallet_address !== profile.user.wallet_address && (
														<CreatorsCard key={i} user={user} featured />
													)
											)
										)}
									</div>
									<div className="w-full flex flex-row items-center justify-center gap-5">
										<span className="text-color-7 font-semibold text-lg">350+ communities to discover</span>
										<Button
											href="/creators"
											variant="outlined"
											className="bg-transparent font-semibold text-[13px] text-color-7"
											size="sm"
										>
											VIEW ALL
										</Button>
									</div>
								</div>
							)}
						</div>
					</>
				) : (
					<NotFound404 text="Sorry, the profile you are looking for cannot be found" />
				)}
			</Layout>
		</>
	)
}

const RenderTabContent: FC<{
	view: ProfileView
}> = ({ view }) => {
	switch (view) {
		case ProfileView.Community:
			return (
				<>
					<CommunityView className="-translate-y-[80px] lg:translate-y-0 lg:mb-20" />
					<FeedView className="-translate-y-[80px] lg:translate-y-0 lg:mb-20" />
				</>
			)

		case ProfileView.Participate:
			return <ParticipateView className="-translate-y-[80px] lg:translate-y-0 lg:mb-20" />

		case ProfileView.Memberships:
			return <MembershipsView />

		default:
			return null
	}
}

export default Profile
