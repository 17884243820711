import { SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { Avatar, Button, Modal, Pagination, Tag, UserCircles } from '@components/core'
import {
	CubeIcon,
	DiscordIcon,
	EthEnsIcon,
	GitHubIcon,
	GlobeIcon,
	InstagramIcon,
	MirrorIcon,
	RedditIcon,
	RightArrowIcon,
	RightChevronIcon,
	ShareIcon,
	SnapshotIcon,
	TwitterIcon,
} from '@components/icons'
import ToolTip from '@components/NewDashboard/ToolTip'
import StyledLoading from '@components/StyledLoading'
import useMembershipsByOwnerLength from '@hooks/query/membership/useMembershipsByOwnerLength'
import useUserMemberships from '@hooks/query/membership/useUserMemberships'
import useInfiniteFeed from '@hooks/query/useInfiniteFeed'
import useProfile from '@hooks/query/useProfile'
import useVestsByAddress from '@hooks/query/useVestsByAddress'
import useCopyClipboard from '@hooks/useCopyClipboard'
import useCurrentUser from '@hooks/useCurrentUser'
import useFollow from '@hooks/useFollow'
import { useTokenDisplayName } from '@hooks/useTokenDisplayName'
import useUnfollow from '@hooks/useUnfollow'
import useWindowSize from '@hooks/useWindowSize'
import backend from 'apiUtils/backend'
import clsx from 'clsx'
import { formatDistanceToNow } from 'date-fns'
import Decimal from 'decimal.js'
import { getTokenFromServer } from 'helpers/queryFunctions'
import Image from 'next/image'
import Link from 'next/link'
import { Content } from 'pages/[profile]/memberships'
import { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { shortenAddress, shortenAddressWithNoDots } from 'utils'
import { GetContractTypeEnum, getTokenContract } from 'utils/getContract'
import { getMilliFromMinutes } from 'utils/rq-settings'
import { checkIsEth, getDisplayName, getTokenDisplayName, getUsername } from '../../helpers/utils'
import { FeedItem, FeedType } from '../../interfaces/feed.interface'
import { AirdropUniqueLink, FollowProfile, Owner, ProfileData, Token, User } from '../../interfaces/interfaces'

// TODO: replace dummy data
const date = new Date()
export const dummyUsers = [
	{
		id: '1234',
		gradient_1: '#000',
		gradient_2: '#fff',
		createdAt: date,
		updatedAt: date,
		wallet_address: '1234',
		nonce: '123',
		is_verified: true,
		has_reward_mainnet: true,
		has_reward_ropsten: true,
		has_reward_matic: true,
		has_reward_mumbai: true,
		hasVestBeta: true,
	},
	{
		id: '3434',
		gradient_1: '#111',
		gradient_2: '#fff',
		createdAt: date,
		updatedAt: date,
		wallet_address: '1234',
		nonce: '123',
		is_verified: true,
		has_reward_mainnet: true,
		has_reward_ropsten: true,
		has_reward_matic: true,
		has_reward_mumbai: true,
		hasVestBeta: true,
	},
	{
		id: '2134',
		gradient_1: '#222',
		gradient_2: '#fff',
		createdAt: date,
		updatedAt: date,
		wallet_address: '1234',
		nonce: '123',
		is_verified: true,
		has_reward_mainnet: true,
		has_reward_ropsten: true,
		has_reward_matic: true,
		has_reward_mumbai: true,
		hasVestBeta: true,
	},
	{
		id: '6334',
		gradient_1: '#333',
		gradient_2: '#fff',
		createdAt: date,
		updatedAt: date,
		wallet_address: '1234',
		nonce: '123',
		is_verified: true,
		has_reward_mainnet: true,
		has_reward_ropsten: true,
		has_reward_matic: true,
		has_reward_mumbai: true,
		hasVestBeta: true,
	},
	{
		id: '2234',
		gradient_1: '#444',
		gradient_2: '#fff',
		createdAt: date,
		updatedAt: date,
		wallet_address: '1234',
		nonce: '123',
		is_verified: true,
		has_reward_mainnet: true,
		has_reward_ropsten: true,
		has_reward_matic: true,
		has_reward_mumbai: true,
		hasVestBeta: true,
	},
	{
		id: '3234',
		gradient_1: '#555',
		gradient_2: '#fff',
		createdAt: date,
		updatedAt: date,
		wallet_address: '1234',
		nonce: '123',
		is_verified: true,
		has_reward_mainnet: true,
		has_reward_ropsten: true,
		has_reward_matic: true,
		has_reward_mumbai: true,
		hasVestBeta: true,
	},
	{
		id: '4234',
		gradient_1: '#666',
		gradient_2: '#fff',
		createdAt: date,
		updatedAt: date,
		wallet_address: '1234',
		nonce: '123',
		is_verified: true,
		has_reward_mainnet: true,
		has_reward_ropsten: true,
		has_reward_matic: true,
		has_reward_mumbai: true,
		hasVestBeta: true,
	},
] as User[]

export const CoverImage: FC<{ profile: ProfileData }> = ({ profile }) => {
	return (
		<div
			className="relative bg-cover w-full h-64 rounded-b-2xl bg-center"
			style={{
				backgroundImage: profile.user.cover_pic_url
					? `url(${profile.user.cover_pic_url})`
					: 'linear-gradient(265.73deg, #ADB6D7 47.44%, #8F99BD 91.94%)',
			}}
		/>
	)
}

const TwitterTag: FC<{ handle: string }> = ({ handle }) => (
	<Tag
		className={clsx(
			handle ? 'border-[#1DA1F2] text-[#1DA1F2]' : 'border-dark-6 text-dark-6',
			'bg-transparent border font-normal text-[13px] h-[33px]'
		)}
		label={handle ?? 'not verified'}
		iconLeft={<TwitterIcon className={clsx(handle ? 'fill-[#1DA1F2]' : 'fill-dark-6', 'w-5 h-5')} />}
	/>
)

/**
 * Contains the profile data displayed at the top of a user's profile page
 * @param props
 * @returns JSX
 */
export const ProfileCard: FC<{ profile: ProfileData; isSelf: boolean; isFollowing: boolean }> = ({
	profile,
	isSelf,
	isFollowing,
}) => {
	const followMutation = useFollow()
	const unfollowMutation = useUnfollow()
	const [, width] = useWindowSize()
	const followersSubset = profile.followers.slice(0, width < 1024 ? 16 : 12)
	// const isMember = false
	const displayName = getDisplayName(profile.user, false, 5, true)
	const userName = getUsername(profile.user, false, 5, true)

	const [showFollowersModal, setShowFollowersModal] = useState(false)
	const [showShareProfileModal, setShowShareProfileModal] = useState(false)
	const profileLink = `coinvise.co/${profile.user.user_name ?? profile.user.wallet_address}`
	const [isCopied, copy] = useCopyClipboard(false, 1500)

	const membershipsLength = useMembershipsByOwnerLength()

	return (
		<div className="drop-shadow-lg bg-light-0 dark:bg-dark-9 rounded-4xl py-5 lg:py-10 mx-auto w-full lg:w-[911px] lg:h-[269px] -translate-y-[80px]">
			{/* Followers Modal */}
			<Modal padding="p-5 pb-0 sm:p-10 m-8" isOpen={showFollowersModal} onClose={() => setShowFollowersModal(false)}>
				<div className="w-full h-full pb-5 text-[22px] font-semibold">
					<span>{profile.followers.length} Members</span>
				</div>
				<div className="text-color-8 flex flex-col pb-8 w-full overflow-y-auto gap-3 sm:gap-6">
					{profile.followers.map((user, i) => (
						<Link key={i} href={`/${user.wallet_address}`} passHref>
							<a onClick={() => setShowFollowersModal(false)} className="cursor-pointer flex flex-row gap-3">
								<Avatar
									size="md"
									profilePicUrl={user.profile_pic_url}
									gradient1={user.gradient_1}
									gradient2={user.gradient_2}
								/>
								<Tag
									label={getUsername(user, false, 5, true)}
									className="min-w-[40px] justify-center bg-transparent border border-color-4 px-2"
								/>
							</a>
						</Link>
					))}
				</div>
			</Modal>
			{/* Share profile Modal */}
			<Modal
				size="sm"
				padding="p-10 m-4 sm:m-8"
				isOpen={showShareProfileModal}
				onClose={() => setShowShareProfileModal(false)}
			>
				<div className="w-full h-full pb-5 text-[22px] font-semibold">
					<span>Share your profile</span>
				</div>
				<div className="text-color-8 flex flex-col w-full overflow-y-auto gap-5">
					<input
						disabled
						className="bg-gradient1 bg-clip-text text-transparent rounded-2xl p-4 border border-color-6 text-[18px] font-semibold"
						value={profileLink}
					/>
					<span className="text-color-6 font-normal text-[14px] mb-5">
						Invite members and spark collaboration with widgets for airdrops, quests, and more
					</span>
					<Button isSuccess={isCopied} successContent="Copied" onClick={() => copy(profileLink)}>
						Copy link
					</Button>
				</div>
			</Modal>
			{/* Profile Card Contents */}
			<div className="flex flex-col lg:flex-row justify-center items-center h-full w-full px-5 lg:px-10 lg:pr-0">
				<Avatar
					className="w-[133px] h-[133px] lg:w-[169px] lg:h-[169px]"
					profilePicUrl={profile?.user.profile_pic_url}
					gradient1={profile?.user.gradient_1}
					gradient2={profile?.user.gradient_2}
				/>
				<div className="flex flex-col w-full lg:px-10 justify-center">
					<div className="flex flex-col lg:flex-row mb-5 lg:mb-[30px] items-center lg:gap-5">
						<span className="leading-none text-3xl text-dark-2 text-[31px] font-semibold my-5 lg:my-0">
							{displayName}
						</span>
						<div className="flex flex-row items-center gap-4">
							<Tag className="bg-dark-8 text-dark-4 font-semibold text-[17px] h-[33px]" label={`@${userName}`} />
							{profile.user.twitter_handle ? (
								<a href={`https://twitter.com/${profile.user.twitter_handle}`}>
									<TwitterTag handle={profile.user.twitter_handle} />
								</a>
							) : (
								<TwitterTag handle={profile.user.twitter_handle} />
							)}
						</div>
					</div>
					<div className="relative flex flex-col lg:flex-row gap-4 lg:mb-[30px] h-[30px] lg:h-[55px]">
						{isSelf ? (
							<Button href="/profile" className="text-[15px] lg:text-[18px] text-constantWhite h-[30px] lg:h-[55px]">
								Manage Profile
							</Button>
						) : (
							<Button
								variant="solidInverted"
								className={clsx(
									'lg:absolute text-[15px] lg:text-[18px] lg:px-8 text-constantWhite h-[30px] lg:h-[55px] flex items-center justify-center',
									membershipsLength ? 'lg:justify-start lg:pl-14' : 'lg:justify-center'
								)}
								onClick={
									isFollowing ? () => unfollowMutation.mutate(profile.user) : () => followMutation.mutate(profile.user)
								}
							>
								{!isFollowing ? 'Follow' : 'Unfollow'}
							</Button>
						)}
						{!isSelf && membershipsLength && (
							<Button
								href={`${getUsername(profile?.user)}/memberships`}
								className="hidden lg:flex shrink-0 items-center justify-center flex-row text-constantWhite h-[30px] lg:h-[55px] lg:max-w-max lg:ml-auto text-[15px] lg:text-[18px] lg:px-32"
							>
								View Membership Tiers
								<RightArrowIcon className="ml-1 stroke-constantWhite" />
							</Button>
						)}
					</div>
					<div className="mt-5 lg:mt-0">
						{profile.followers.length > 0 ? (
							<UserCirclesStat
								onClick={() => setShowFollowersModal(true)}
								wrap
								users={followersSubset}
								prefix="FOLLOWED BY"
								suffix={
									profile.followers.length - followersSubset.length > 0 &&
									`+ ${profile.followers.length - followersSubset.length} more`
								}
								size="md"
							/>
						) : (
							<div className="flex flex-row uppercase font-[13px] text-dark-6 font-semibold text-sm">
								{isSelf ? (
									<div className="hidden lg:flex gap-2 items-center">
										<span>Complete and share your profile to onboard new members</span>
										<button className="cursor-pointer" onClick={() => setShowShareProfileModal(true)}>
											<ShareIcon className="stroke-dark-6 hover:stroke-dark-3 hover:scale-125 transform w-3 h-3" />
										</button>
									</div>
								) : (
									<span className="mx-auto lg:mx-0">0 Followers</span>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

const MembershipCard: FC<{
	tierAuthor: Owner
	tierImgUrl: string
	tierTitle: string
}> = ({ tierImgUrl, tierTitle, tierAuthor }) => (
	<Link href={`/${tierAuthor.user_name ?? tierAuthor.wallet_address}/memberships`}>
		<a className="rounded-2xl border-color-3 border p-5 flex items-center flex-row gap-8 max-w-max cursor-pointer">
			<Avatar
				customSize="w-[60px] h-[60px]"
				gradient1={tierAuthor.gradient_1}
				gradient2={tierAuthor.gradient_2}
				profilePicUrl={tierAuthor.profile_pic_url}
				href={`${tierAuthor.user_name ?? tierAuthor.wallet_address}`}
				className="hidden md:flex items-center"
			/>
			{tierImgUrl && (
				<div className="relative h-full w-24">
					<Image className="rounded-md shrink-0" src={tierImgUrl} alt="NFT" layout="fill" objectFit="cover" />
				</div>
			)}

			<div className="flex flex-col gap-[5px]">
				<span className="font-semibold text-[25px] text-color-8 leading-none">{tierTitle}</span>
				<span className="font-semibold text-[16px] text-color-6">TIER</span>
			</div>
		</a>
	</Link>
)

export const CommunityView: FC<{ className: string }> = ({ className }) => {
	const { data: profile } = useProfile()
	const networkChainId = profile.user.primaryTokenChain
	const [primaryToken, setPrimaryToken] = useState<Token>()
	const [primaryTokenSupply, setPrimaryTokenSupply] = useState<number>()

	useEffect(() => {
		if (profile && profile.user.primaryTokenAddress) {
			getTokenFromServer(profile.user.primaryTokenChain, profile.user.primaryTokenAddress, true).then((res) =>
				setPrimaryToken(res)
			)
		}
	}, [profile])

	useEffect(() => {
		if (primaryToken) {
			const tokenContract = getTokenContract(primaryToken.address, {
				chainId: Number(primaryToken.network_chain_id),
				type: GetContractTypeEnum.ReadOnly,
			})

			tokenContract.totalSupply().then((_supply) => {
				setPrimaryTokenSupply(
					new Decimal(_supply.toString())
						.div(10 ** (primaryToken.decimals ?? 18))
						.toDecimalPlaces(4)
						.toNumber() || 0.0
				)
			})
		}
	}, [primaryToken])

	const tags = profile ? profile.user.tags.map((tag) => tag.name) : []

	const wallet = {
		token: { label: primaryToken ? `${primaryToken} Contract` : null, link: '' },
		address: {
			label: profile.user.ens_name || shortenAddressWithNoDots(profile.user.wallet_address, 3),
			link: `https://etherscan.io/address/${profile.user.wallet_address}`,
		},
		website: { label: profile.user.website_url, link: profile.user.website_url },
	}

	const socials = {
		Instagram: profile.user.insta_handle ? `https://instagram.com/${profile.user.insta_handle}` : null,
		GitHub: profile.user.github_profile,
		Reddit: profile.user.reddit_page,
		Discord: profile.user.discord_invite,
		Mirror: profile.user.mirror_profile,
		Snapshot: profile.user.snapshot_space,
	}

	const description = profile.user.bio

	const { data: membershipPages } = useUserMemberships(profile.user.wallet_address)

	return (
		<div
			className={clsx(
				className,
				'mb-20 mx-auto p-5 lg:p-10 gap-10 lg:gap-16 grid grid-flow-rows auto-rows-fit rounded-4xl w-full lg:w-[900px] min-h-fit border border-color-6'
			)}
		>
			{/* ROW 1 */}
			{(primaryToken || (tags && tags.length > 0)) && (
				<div className="flex flex-col lg:flex-row gap-5">
					{primaryToken && (
						<div className="flex flex-row gap-[10px] items-center">
							<Avatar
								className="w-[30px] h-[30px]"
								profilePicUrl={profile?.user.profile_pic_url}
								gradient1={profile?.user.gradient_1}
								gradient2={profile?.user.gradient_2}
							/>
							<span className="text-lg font-semibold">${primaryToken.name}</span>
							{checkIsEth(networkChainId) ? (
								<Image src="/images/eth.svg" height={15} width={15} />
							) : (
								<Image src="/images/polygon.svg" height={15} width={15} />
							)}
							<Image src="/images/NewTab.svg" height={15} width={15} />
						</div>
					)}
					{tags && tags.length > 0 && (
						<div className={clsx(primaryToken && 'lg:ml-auto', 'flex flex-wrap lg:h-[22px] gap-5 text-color-6')}>
							{tags.map((tag, i) => (
								<Tag key={i} variant="secondary" className="text-[12px] uppercase" label={tag} />
							))}
						</div>
					)}
				</div>
			)}
			{/* ROW 2 */}
			{primaryToken && (
				<div className="grid grid-flow-row sm:grid-flow-col auto-cols-max justify-between font-semibold gap-6">
					<div className="flex flex-col gap-5">
						<span>OVERVIEW</span>
						<div className="flex flex-row gap-10 text-color-5">
							{/* <div className="flex flex-col">
								<span className="text-[13px]">TOKEN HOLDERS</span>
								<span className="text-lg">{primaryToken.holders}</span>
							</div> */}
							{/* <div className="flex flex-col">
								<span className="text-[13px]">TOKEN REPUTATION</span>
								<span className="text-lg">{primaryToken.rep}</span>
							</div> */}
							{/* <div className="flex flex-row gap-10 text-color-5"> */}
							<div className="flex flex-col">
								<span className="text-[13px]">TOKENS DISTRIBUTED</span>
								<span className="text-lg">{primaryToken.tokensDistributed}</span>
							</div>
							<div className="flex flex-col">
								<span className="text-[13px]">TOTAL SUPPLY</span>
								<span className="text-lg">{primaryTokenSupply?.toLocaleString()}</span>
							</div>
							{/* </div> */}
						</div>
					</div>
					{/* <div className="flex flex-col gap-5">
						<span>TOKENOMICS</span>
						<div className="flex flex-row gap-10 text-color-5">
							<div className="flex flex-col">
								<span className="text-[13px]">TOKENS DISTRIBUTED</span>
								<span className="text-lg">{primaryToken.tokensDistributed}</span>
							</div>
							<div className="flex flex-col">
								<span className="text-[13px]">TOTAL SUPPLY</span>
								<span className="text-lg">{primaryTokenSupply?.toLocaleString()}</span>
							</div>
						</div>
					</div> */}
				</div>
			)}
			{/* ROW 3 */}
			{description && (
				<div className="flex flex-col gap-5">
					<div className="flex flex-row items-center gap-2">
						<span className="font-semibold">DESCRIPTION</span>
						<RightChevronIcon className="stroke-color-6 w-3 h-3" />
					</div>
					<span className="font-normal text-color-5">{description}</span>
				</div>
			)}
			{/* ROW 4 */}
			{membershipPages?.pages[0].data.length > 0 && (
				<div className="flex flex-col gap-5">
					<span className="font-semibold">MEMBERSHIPS</span>
					{membershipPages?.pages.map((page) =>
						page?.data.map((data) => (
							<MembershipCard
								key={data.id}
								tierAuthor={data.membership.owner}
								tierTitle={data.membership.title}
								tierImgUrl={data.membership.image}
							/>
						))
					)}
				</div>
			)}
			{/* ROW 5 */}
			<div className="flex flex-col gap-5">
				<span className="font-semibold">LINKS</span>
				<div className="flex flex-wrap gap-4">
					{Object.entries(wallet).map(
						([key, item]) =>
							item.label &&
							item.link && (
								<Button
									href={item.link}
									key={key}
									variant="outlined"
									className="px-2 text-[15px] text-color-7 h-[30px] hover:text-color-8"
									size="sm"
								>
									<div className="flex flex-row items-center gap-2">
										{key === 'token' ? (
											<CubeIcon className="stroke-color-4 w-4 h-4" />
										) : key === 'address' ? (
											<EthEnsIcon className="fill-color-4 h-4" />
										) : key === 'website' ? (
											<GlobeIcon className="fill-color-4 w-4 h-4" />
										) : undefined}
										<span>{item.label}</span>
									</div>
								</Button>
							)
					)}
				</div>
				<div className="flex flex-wrap gap-4">
					{Object.entries(socials).map(
						([platform, link]) =>
							link && (
								<Button
									href={link}
									key={platform}
									variant="outlined"
									className="px-2 text-[15px] text-color-7 h-[30px] hover:text-color-8"
									size="sm"
								>
									<div className="flex flex-row gap-2 items-center">
										{platform === 'Instagram' ? (
											<InstagramIcon className="w-4 h-4" gradient />
										) : platform === 'Discord' ? (
											<DiscordIcon className="w-4 h-4 fill-[#5865F2]" />
										) : platform === 'Reddit' ? (
											<RedditIcon className="w-4 h-4 fill-[#FF5700]" />
										) : platform === 'GitHub' ? (
											<GitHubIcon className="w-4 h-4 fill-color-9" />
										) : platform === 'Mirror' ? (
											<MirrorIcon className="w-4 h-4 fill-[#5A5A5A]" />
										) : platform === 'Snapshot' ? (
											<SnapshotIcon className="w-4 h-4 fill-[#FCAD36]" />
										) : undefined}
										<span>{platform}</span>
									</div>
								</Button>
							)
					)}
				</div>
			</div>
		</div>
	)
}

/**
 * Displays a list of user icons with a prefix & suffix (i.e. followed by x users)
 * @param props
 * @returns JSX
 */
const UserCirclesStat: FC<{
	onClick?: () => void
	wrap?: boolean
	prefix?: string
	suffix?: string
	users: User[] | FollowProfile[]
	size: 'sm' | 'md' | 'lg' | 'xl'
}> = ({ onClick, wrap = false, prefix, suffix, users, size }) => {
	const textStyle = 'leading-none text-[13px] font-semibold text-color-4'

	const content = (
		<>
			<UserCircles
				className={clsx(wrap && 'h-[80px] lg:h-full')}
				size={size}
				users={users}
				clickable={false}
				ring={false}
			/>
			{suffix && <span className={textStyle}>{suffix}</span>}
		</>
	)

	return (
		<div className="flex flex-col lg:flex-row items-center gap-2">
			{prefix && <span className={textStyle}>{prefix}</span>}
			{onClick ? (
				<button
					onClick={onClick}
					className={clsx(onClick && 'cursor-pointer', 'flex flex-col lg:flex-row items-center gap-2')}
				>
					{content}
				</button>
			) : (
				<div className="flex flex-col lg:flex-row items-center gap-2">{content}</div>
			)}
		</div>
	)
}

/**
 * Cards which contain the airdrops, vests, quests
 * @param props
 * @returns JSX
 */
const WidgetCard: FC<{ className?: string; title: string; buttonLabel: string; children?: JSX.Element; href: string }> =
	({ className, href, title, buttonLabel, children }) => {
		return (
			<div
				className={clsx(
					className,
					'rounded-4xl h-full w-72 sm:w-96 py-5 px-8 bg-color-0 flex flex-col gap-5 items-center justify-center'
				)}
			>
				<ToolTip label={title} placement="top">
					<span className="text-[23px] font-semibold truncate text-center w-full">{title}</span>
				</ToolTip>
				{children}
				<Button href={href}>{buttonLabel}</Button>
			</div>
		)
	}

const AirdropCard: FC<{ className?: string; title: string; buttonLabel: string; href: string; claimFraction: string }> =
	({ className, title, buttonLabel, href, claimFraction }) => {
		return (
			<WidgetCard
				title={title}
				buttonLabel={buttonLabel}
				href={href}
				className={clsx(className, 'flex shrink-0 lg:w-[275px] lg:mr-[18px]')}
			>
				<div className="flex flex-col items-center text-color-6">
					<span className="text-[13px] font-semibold uppercase">Claimed</span>
					<span className="text-[26px] font-semibold">{claimFraction}</span>
				</div>
			</WidgetCard>
		)
	}

/**
 * Used in the Participate tab of profile to display airdrops, vests, quests cards
 * @param props
 * @returns JSX
 */
const WidgetWrapper: FC<{
	className?: string
	pages: number
	title: string
	users: User[]
	children: JSX.Element
	isSelf: boolean
}> = ({ className, pages, title, children }) => {
	// const prefix = title === 'Airdrops' ? 'CLAIMED BY' : title === 'Quests' ? 'APPLIED BY' : 'RECIPIENTS'
	// const suffix = '+ 99 more'

	// const { isOpen: toggled, onToggle: handleToggle } = useDisclosure()
	const [index, setIndex] = useState(0)

	const handleNext = (): void => {
		setIndex((i) => (i + 1) % pages)
	}

	const handlePrev = (): void => {
		setIndex((i) => {
			if (i === 0) return ((i - 1) % pages) + pages
			return i - (1 % pages)
		})
	}

	return (
		<div className={clsx(className, 'w-full rounded-4xl bg-color-2 h-full flex flex-col p-5 gap-8')}>
			<div className="flex flex-row w-full px-5 items-center">
				<span className="text-2xl font-semibold text-transparent bg-clip-text bg-gradient1">{title}</span>
				{/* <div className="ml-auto">
					{isSelf ? (
						<ToggleSwitch
							handleToggle={handleToggle}
							toggled={toggled}
							toggleOnLabel="VISIBLE"
							toggleOffLabel="HIDDEN"
						/>
					) : (
						<UserCirclesStat users={users} size="sm" prefix={prefix} suffix={suffix} />
					)}
				</div> */}
			</div>
			{/* Carousel View */}
			<div
				className={clsx(
					'overflow-y-auto grid grid-flow-col h-[231px] overflow-hidden w-72 sm:w-96 lg:w-full relative mx-auto',
					pages <= 1 && 'mb-12'
				)}
			>
				{/* Inner Container */}
				<div
					className={clsx('whitespace-nowrap inline-flex transform transition duration-300 ease-in-out justify-center')}
					style={{ transform: `translateX(-${(100 / pages) * index}%)` }}
				>
					{/* Cards */}
					{children}
				</div>
			</div>
			{/* Pagination */}
			{pages > 1 && <Pagination index={index} pages={pages} handleNext={handleNext} handlePrev={handlePrev} />}
		</div>
	)
}

/**
 * Cards with a single button for a user to take action.
 * Used to display instead of widget cards when the user has no airdrops, vests, or quests to show
 * @param props
 * @returns JSX
 */
const ActionCard = ({ children }): JSX.Element => {
	return (
		<div className="rounded-4xl border border-color-4 p-8 sm:w-96 flex w-full h-full mx-auto items-center justify-center">
			{children}
		</div>
	)
}

export const ParticipateView: FC<{ className?: string }> = ({ className }) => {
	const { data: loggedInUser } = useCurrentUser()
	const { data: profile } = useProfile()
	const userAddress = profile?.user?.wallet_address
	const isSelf = loggedInUser?.publicAddress === userAddress

	const { data: vests, isLoading: isVestsLoading } = useVestsByAddress('creator', userAddress)
	const { data: airdropsData, isLoading: isAirdropsLoading } = useQuery<{ airdrops: AirdropUniqueLink[] }>(
		['airdrops-uniquelink', userAddress],
		() => backend.get(`/airdrop/user/${userAddress}`).then((res) => res.data),
		{
			enabled: !!userAddress,
			staleTime: getMilliFromMinutes(5),
		}
	)
	const { airdrops } = airdropsData ?? {}

	const [, width] = useWindowSize()
	const isMobile = width < 1024

	const vestsSize = vests?.length
	const airdropsSize = airdrops?.length
	const airdropsPerPage = isMobile ? 1 : 3
	const airdropsPages = Math.ceil(airdropsSize / airdropsPerPage)

	return (
		<div className={clsx(className, 'mx-auto flex flex-col w-full lg:w-[900px] gap-10')}>
			{/* Airdrops */}
			<WidgetWrapper pages={airdropsPages} isSelf={isSelf} title="Airdrops" users={dummyUsers}>
				{isAirdropsLoading ? (
					<StyledLoading />
				) : !airdrops?.length && isSelf ? (
					<ActionCard>
						<Button href="/airdrop">Create Airdrop</Button>
					</ActionCard>
				) : !airdrops?.length && !isSelf ? (
					<span className="h-full flex items-center text-lg text-color-8 w-full sm:w-96 justify-center">
						No Airdrops
					</span>
				) : (
					<>
						{airdrops.map((airdrop) => (
							<AirdropCard
								key={airdrop.id}
								href={`claim/${airdrop.slug}`}
								title={airdrop.description}
								buttonLabel={`Claim ${airdrop.amount_per_recipient} $${getTokenDisplayName(airdrop.token)}`}
								claimFraction={`${airdrop.claims.length}/${airdrop.number_of_recipients}`}
							/>
						))}
						{/* Filler airdrop cards for desktop view so that paging through every third item translates enough horizontally */}
						{!isMobile &&
							airdropsSize > 3 &&
							airdropsSize % 3 > 0 &&
							Array(3 - (airdropsSize % 3))
								.fill(0)
								.map((x, i) => (
									<AirdropCard key={i} className="invisible" href="" title="" buttonLabel="" claimFraction="" />
								))}
					</>
				)}
			</WidgetWrapper>
			<div className="grid grid-flow-row lg:grid-flow-col row gap-10">
				{/* Quests */}
				{/* <WidgetWrapper
					className="lg:max-w-[426px]"
					pages={questsSize}
					isSelf={isSelf}
					title="Quests"
					users={dummyUsers}
				>
					{isQuestsLoading ? (
						<StyledLoading />
					) : !quests.length && isSelf ? (
						<ActionCard>
							<Button href="/quest">Create Quest</Button>
						</ActionCard>
					) : !quests.length && !isSelf ? (
						<span className="h-full flex items-center text-lg text-color-8 w-full sm:w-96 justify-center">
							No Quests
						</span>
					) : (
						<>
							{quests.map((quest) => (
								<WidgetCard
									key={quest.id}
									title={quest.name}
									buttonLabel="Visit Quest page"
									href={'/quest/' + quest.id}
								/>
							))}
						</>
					)}
				</WidgetWrapper> */}
				{/* Vests */}
				<WidgetWrapper className="lg:max-w-[426px]" pages={vestsSize} isSelf={isSelf} title="Vests" users={dummyUsers}>
					{isVestsLoading ? (
						<StyledLoading />
					) : !vests.length && isSelf ? (
						<ActionCard>
							<Button href="/vesting">Create Vesting Schedule</Button>
						</ActionCard>
					) : !vests.length && !isSelf ? (
						<span className="h-full flex items-center text-lg text-color-8 w-full sm:w-96 justify-center">
							No Vests
						</span>
					) : (
						<>
							{vests.map((vest) => (
								<WidgetCard
									key={vest.id}
									title={vest.description !== '' ? vest.description : 'No description Available'}
									buttonLabel="Visit Vest page"
									href={'/vesting/' + vest.id}
								/>
							))}
						</>
					)}
				</WidgetWrapper>
			</div>
		</div>
	)
}

const FeedEntry: FC<FeedItem> = ({ user, token, description, keyword, createdAt, ...rest }) => {
	const userName = getUsername(user, false, 5, true)

	const { data: tokenDisplayName, isLoading: isTokenNameLoading } = useTokenDisplayName({
		address: token?.address,
		chainId: Number(token?.network_chain_id),
		defaultValue: token?.symbol ?? token?.name,
	})

	const title = isTokenNameLoading
		? `${userName} ${keyword} ${token?.symbol ?? token?.name ?? shortenAddressWithNoDots(token?.address)}`
		: `${userName} ${keyword} ${tokenDisplayName}`
	return (
		<div className="flex flex-row w-full gap-5 lg:justify-start p-5 sm:px-20 py-10 even:bg-color-2 border-l border-r border-color-6 first:border-t first:rounded-t-4xl last:rounded-b-4xl last:border-b">
			<Avatar size="lg" profilePicUrl={user.profile_pic_url} gradient1={user.gradient_1} gradient2={user.gradient_2} />
			<div className="flex flex-col">
				<span className="text-lg font-semibold">
					{title}
					{rest.type === FeedType.AIRDROP || rest.type === FeedType.REWARD
						? ` to ${rest.numberOfRecipients} Recipients`
						: ``}
				</span>
				<span className="text-[16px] font-normal text-color-6">{description}</span>
				<div className="flex flex-wrap gap-5 mt-8">
					<a href={`https://etherscan.io/address/${user.wallet_address}`}>
						<Tag
							className="text-[12px] font-normal bg-color-0 border-0 h-[25px]"
							label={shortenAddress(user.wallet_address, 0, 4)}
						/>
					</a>
					<Tag
						variant="secondary"
						className="uppercase rounded-full text-color-7 font-semibold text-[13px] h-[25px]"
						label={rest.type}
					/>
					<Tag
						className="bg-transparent h-[25px]"
						label={formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
					/>
				</div>
			</div>
		</div>
	)
}

export const FeedView: FC<{ className?: string }> = ({ className }) => {
	const { data: loggedInUser } = useCurrentUser()
	const { data: profile } = useProfile()
	const userAddress = profile?.user?.wallet_address
	const isSelf = loggedInUser?.publicAddress === userAddress
	const { data, isLoading } = useInfiniteFeed(profile.user.wallet_address)
	const feed = data?.pages.map((grp) => grp.feed).flat()
	return (
		<div className={clsx(className, 'mx-auto grid grid-flow-rows auto-rows-min w-full lg:w-[900px] min-h-fit')}>
			{isLoading ? (
				<div className="sm:px-20 rounded-4xl border border-color-6 p-10">
					<SkeletonCircle size="14" />
					<SkeletonText mt="4" noOfLines={4} spacing="4" />
				</div>
			) : (
				<>
					{feed && feed.length > 0 ? (
						feed.map((entry) => <FeedEntry key={entry.id} {...entry} />)
					) : (
						<div className="rounded-4xl border-color-6 p-10 border text-dark-6 font-semibold text-[18px] flex flex-row gap-5 items-center">
							<Avatar
								size="lg"
								profilePicUrl={profile.user.profile_pic_url}
								gradient1={profile.user.gradient_1}
								gradient2={profile.user.gradient_2}
							/>
							<div className="flex flex-col my-auto">
								<span>No activity found</span>
								{/* TODO: add tag with PortalIcon */}
								{isSelf && (
									<span className="font-normal">Use the tools in the Dashboard to showcase updates in this feed</span>
								)}
							</div>
						</div>
					)}
				</>
			)}
		</div>
	)
}

export const MembershipsView: FC = () => {
	return (
		<div
			className={clsx(
				'mb-20 lg:mb-64 mx-auto lg:p-10 p-5 gap-10 lg:gap-16 grid grid-flow-rows auto-rows-fit rounded-4xl w-full lg:w-[900px] min-h-fit border border-color-6'
			)}
		>
			<Content />
		</div>
	)
}
