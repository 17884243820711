import { Token, User } from './interfaces'

export type FeedItem = (RewardFeed | MintFeed | AirdropFeed) & {
	id: string
	createdAt: string
	updatedAt: string
	description: string

	tokenAddress: string
	token: Token

	userAddress: string
	user: User
}

export enum FeedType {
	REWARD = 'REWARD',
	MINT = 'MINT',
	AIRDROP = 'AIRDROP',
}

export interface FeedResult {
	feed: FeedItem[]
	nextSkip: string
}

interface RewardFeed {
	type: FeedType.REWARD
	keyword: 'sent'
	numberOfRecipients: number
}

interface AirdropFeed {
	type: FeedType.AIRDROP
	keyword: 'sent'
	numberOfRecipients: number
}

interface MintFeed {
	type: FeedType.MINT
	keyword: 'created'
}
